<!--电能设置--基本设置---->
<template>
  
  <div id="energyUseKindSetup" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="headertitle">
      <!-- 能源使用类型设置 -->
      <span>{{$t('energy.enedata265')}}</span>
    </div>
    <div class="header">
      <div class="header-left">
        <div class="header-condition">
          <!-- 年度 -->
          <span>{{$t('energy.enedata046')}}：</span>
          <a-select v-model="selYear" style="width: 150px" >
            <a-select-option
              v-for="(itme, index) in yearList"
              :key="index"
              :value="itme"
            >
              {{ itme }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div>
        <a-button type="primary" style="width: 120px"  @click="search(1)"> {{$t('energy.enedata006')}} </a-button>
        <a-button type="primary" @click="saveEnergyUseKindSetup()">{{$t('energy.enedata173')}}</a-button>
      </div>
    </div>
    <div class="main">
         <a-table :columns="columns" :data-source="data" bordered size="small" :pagination="false" :scroll="{ x: 1500, y: 500 }">
            <template slot="registed" slot-scope="text, record, index,key">
              <a-checkbox   :checked="text"  v-model="record.isregisted"   @click="changeValue2($event,record,key)"/>
            </template>

            <template slot="tenant" slot-scope="text, record, index,key">
              <a-checkbox   :checked="text" v-model="record.tenant" :disabled="!record.isregisted"   @click="changeValue2($event,record,key)"/>
            </template>

            <template slot="cost" slot-scope="text, record, index,key">
              <a-checkbox   :checked="text" v-model="record.cost"  :disabled="!record.isregisted"   @click="changeValue2($event,record,key)"/>
            </template>

            <template    slot-scope="text, record, index,key " >
              <div v-for="(item, idx) in checks" :key="idx">
                  <a-checkbox  :checked="text" :disabled="!record.isregisted || record.op5Disabled"   @change="changeValue2($event,record,key)"/>
              </div>
            </template>
            
            <template slot="eneCompanyNo" slot-scope="text, record">
              <div class="editable-row-operations">
                <span v-if="record.eneKind == 1">
                    <a-select v-model="record.eneCompanyNo" style="width: 150px" :disabled="!record.isregisted" @change="changeEneCompanyValue($event,record)">
                        <a-select-option  v-for="(itme, idx) in record.eneCompanyList" :key="idx"  :value="itme.no"> {{ itme.text }} </a-select-option> 
                    </a-select>
                </span>
                <span v-else>
                    <a-input  style="width:100%;"  v-model="record.eneCompanyName" :disabled="!record.isregisted"  />
                </span>
              </div>
            </template>

            <template slot="kindType" slot-scope="text, record">
              <div class="editable-row-operations">
                <span v-if="record.eneKind !=1">
                    <a-select v-model="record.kindType" style="width: 150px" :disabled="!record.isregisted">
                        <a-select-option  v-for="(itme, idx) in record.kindTypeList" :key="idx"  :value="itme.no"> {{ itme.text }} </a-select-option> 
                    </a-select>
                </span>
                <span v-else>
                   {{text}}
                </span>
              </div>
            </template>

            <template slot="gasKind" slot-scope="text, record">
              <div class="editable-row-operations">
                <span v-if="record.eneKind == 2">
                    <a-select v-model="record.gasKind" style="width: 150px" :disabled="!record.isregisted" @change="changeGasKindValue($event,record)">
                        <a-select-option  v-for="(itme, idx) in record.gaskindList" :key="idx"  :value="itme.no"> {{ itme.text }} </a-select-option> 
                    </a-select>
                </span>
                <span v-else>
                   {{text}}
                </span>
              </div>
            </template>

            <template slot="unit" slot-scope="text, record">
              <div class="editable-row-operations">
                <span v-if="record.eneKind == 2">
                    <a-select v-model="record.unit" style="width: 150px" :disabled="!record.isregisted" @change="changeEneKindValue($event,record)">
                        <a-select-option  v-for="(itme, idx) in record.unitList" :key="idx"  :value="itme.no"> {{ itme.text }} </a-select-option> 
                    </a-select>
                </span>
                <span v-else>
                   {{text}}
                </span>
              </div>
            </template>
        </a-table>
    </div>
    
   

  </div>
</template>



<script>
import { getEnergyUseKindSetupData  , saveEnergyUseKindSetupData } from "../../../api/energy";

const columns = [];


   
export default {
  name: "energyUseKindSetup",
  data() {
      
    return {
      loading: false,
      dataChange:false,
      selYear:'',
      yearList:[],
      data:null,
      checks:['op2Checked','op3Checked','op4Checked','op5Checked'],
      errorMessage:[
          this.$t('energy.enedata265')//"能源使用类型"                              // 0
         ,this.$t('energy.enedata180')//"数据注册成功。"                            // 1
         ,this.$t('energy.enedata181')//"数据注册失败。"                            // 2
         ,this.$t('energy.enedata279')//"确认要保存所设置的能源使用类型吗？"           // 3 
         ,this.$t('energy.enedata280')//"已设置费用信号点，不许取消设置；请在计量点-本司/租户区分设置画面确认费用信号点设置。"   
         ,this.$t('energy.enedata281')//"所选择能源使用类型已设置租户权原分计量信号点，不许取消设置；请在计量点-本司/租户区分设置画面确认租户权原分计量信号点设置。"   
         ,this.$t('energy.enedata282')//"所选择能源使用类型已设置计量信号点，不许取消注册；请在计量点-能源类型设置画面确认计量信号点设置。"   
         ,this.$t('energy.enedata186')//"确认要放弃所编辑的数据吗？"  
         ,""      
        ] ,
      columns:[
          {title: 'NO.'		, dataIndex: 'registNo'		 ,width:50, fixed: 'left'},
          {title: this.$t('energy.enedata212')		  , dataIndex: 'registed'	,  scopedSlots: { customRender: 'registed' }	 ,width:60, fixed: 'left'},
          {title: this.$t('energy.enedata266')	    , dataIndex: 'eneName' ,width:240, fixed: 'left'},
          {title: this.$t('energy.enedata267')			, dataIndex: 'gasKind'			 , key: 'gasKind'	,  scopedSlots: { customRender: 'gasKind' }		 ,width:180},
          {title: this.$t('energy.enedata165')      , dataIndex: 'unit', key: 'unit',  scopedSlots: { customRender: 'unit' },width:180, },
          {title: this.$t('energy.enedata268')      , dataIndex: 'eneCompanyNo'     , key: 'eneCompanyNo'     ,  scopedSlots: { customRender: 'eneCompanyNo' }  ,width:180,},
          {title: this.$t('energy.enedata002'), dataIndex: 'kindType', key: 'kindType',  scopedSlots: { customRender: 'kindType' },width:180,},
          {title: this.$t('energy.enedata269'), dataIndex: 'tenant', key: 'tenant',  scopedSlots: { customRender: 'tenant' },width:150,},
          {
            title: this.$t('energy.enedata088'),
            children:[

              {title: this.$t('energy.enedata270'), dataIndex: 'cost', key: 'cost',  scopedSlots: { customRender: 'cost' },width:95,},
              {title: 'OP2', dataIndex: 'op2Checked', key: 'op2Checked',  scopedSlots: { customRender: 'op2Checked' },width:95,},
              {title: 'OP3', dataIndex: 'op3Checked', key: 'op3Checked',  scopedSlots: { customRender: 'op3Checked' },width:95,},
              {title: 'OP4', dataIndex: 'op4Checked', key: 'op4Checked',  scopedSlots: { customRender: 'op4Checked' },width:95,},
              {title: 'OP5', dataIndex: 'op5Checked', key: 'op5Checked',  scopedSlots: { customRender: 'op5Checked' },width:95,},
            ]
          },
          
          {
            title: this.$t('energy.enedata271'),
            children:[

              {title: this.$t('energy.enedata272'), dataIndex: 'eneEquiv', width:95,},
              {title: this.$t('energy.enedata273'), dataIndex: 'eneEquivNight', width:95,},
              {title: this.$t('energy.enedata165'), dataIndex: 'eneEquivUnit', width:95,},
            ]
          },
          
          {
            title: this.$t('energy.enedata274'),
            children:[
              {title: this.$t('energy.enedata275'), dataIndex: 'wLpgEquivCoefficient',width:95,},
              {title: this.$t('energy.enedata165'), dataIndex: 'wEneUnitLpg',width:95,},
            ]
          },
          {
            title: this.$t('energy.enedata276'), 
            children:[
              {title: this.$t('energy.enedata232'), dataIndex: 'measureType',width:150,},
              {title: this.$t('energy.enedata233'), dataIndex: 'summerMeter',width:150,},
              {title: this.$t('energy.enedata234'), dataIndex: 'winterMeter',width:150,},
            ]
          },
        ]
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadEnergyUseKindSetupData(1);
    }
  },
  async mounted() { 
    //this.loadEnergyUseKindSetupData(1);
  },
  methods:{
    search(action){
      if(this.dataChange){
        this.$confirm({
        title: this.errorMessage[7],
        centered: true,
        onOk: () => {
              this.loadEnergyUseKindSetupData(action);
             }
        });
      }else{
          this.loadEnergyUseKindSetupData(action);
      }
    },
    loadEnergyUseKindSetupData(action){
      let params = {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            selYear:this.selYear,
            tenantId: 0,
      };
      //console.log(params);
      this.loading = true;
    
      getEnergyUseKindSetupData(params)
        .then((res) => {
            //console.log(res);
            this.loading = false;
            let normList = [];
            if(res.errorCode == '00'){
                this.selYear = res.data.selYear;
                this.yearList = res.data.yearList;
                // if(res.data.energyKindDatas!=null){
                //     this.data = res.data.energyKindDatas;
                // }


                if(res.data.energyKindDatas!=null){
                    let arr = [];
                     // console.log("registNo     registed    distributePoint    useBefore   tenant    tenantRightPoint    cost    feePoint    lastUseYear");
                    for (let i = 0; i < res.data.energyKindDatas.length; i++) {
                        let item =  res.data.energyKindDatas[i];
                        item.key = item.registNo;
                        item.vail = '';
                        item.tag = item.registed === 'checked'?1:-1;
                        item.isregisted = item.registed === 'checked';
                        item.tenant = item.tenant==='checked'?item.tenant:false;
                        //console.log(item.cost);
                        item.cost = item.cost=='checked'?item.cost:false;
                        // console.log(item.registNo+"    "+(item.registed === 'checked')+"    "+item.distributePoint+"    "+item.useBefore+"    "
                        // +item.tenant+"    "+item.tenantRightPoint+"    "+item.cost+"    "+item.feePoint+"    "+item.lastUseYear);
                        arr.push(item);
                    }
                    this.data = arr;
                } 
            }
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
      });
    },
    changeValue(index){
      console.log("changeValue>>"+index);
      this.data[index].inputFlag = true;
    },
    changeGasKindValue(sel,record){
      if(sel==="0"){
        console.log("changeGasKindValue>>"+sel);
        record.unit = "0";
      }
      this.dataChange = true;
    },
    changeEneKindValue(sel,record){
      console.log("changeEneKindValue>>"+sel);
      this.dataChange = true;
    },
    changeEneCompanyValue(sel,record){
      if(record.eneCompanyList!=null){
        let item = null;
        record.eneCompanyList.forEach(element => {
          if(element.no == sel){
            item = element.item;
          }
        });
        if(item!=null){
          record.eneEquiv = item.dayDdEneEquiv;
          record.eneEquivNight = item.dayDnEneEquiv;
          record.eneEquivUnit = item.euEneUnitName;
        }
      }
      console.log(sel);
      this.dataChange = true;
    },
    changeValue2(chk,record,obj){
     
       if(obj.key=='registed'){
          this.funcChkregistePoint(chk,record);
        } else if(obj.key=='tenant'){
          this.funcChkTenant(chk,record);
        } else if(obj.key=='cost'                ){
          this.funcChkFee(chk,record);
        } else if(obj.key=='op2Checked'          ){
          
        } else if(obj.key=='op3Checked'          ){
          
        } else if(obj.key=='op4Checked'          ){
          
        } else if(obj.key=='op5Checked'          ){
          
        }
       
    },
    funcChkregistePoint(chk,record){
      chk.preventDefault();

      if(!record.isregisted){
          record.isregisted = true;
          record.registed	  = 'checked';
         if(record.tag==-1){
           record.tag = 0;
         }
      }else{
        if(record.distributePoint){
            let msg = this.errorMessage[5] ;//+this.errorMessage[err];
            this.$message.error(msg);
          }else{
            record.isregisted = false;
            record.registed	 = false;
            if(record.tag==0){
              record.tag = -1;
            }
          }
      }
       this.dataChange = true;
      console.log("record.registed>" + record.registed);
    },
    funcChkTenant(chk,record){
      chk.preventDefault();
      if(!record.tenant){
          record.tenant = 'checked';
      }else{
        if(record.tenantRightPoint){
            let msg = this.errorMessage[5] ;//+this.errorMessage[err];
            this.$message.error(msg);
          }else{
            record.tenant = false;
          }
      }
       this.dataChange = true;
    },
    funcChkFee(chk,record){
      let fee = false;
      chk.preventDefault();
      if(!chk.target.checked ){
        for (let k = 0; k < this.data.length; k++) {
          if(this.data[k].feePoint){
            fee = true;
            break;
          }
        }
      }
      if(!fee){
        let cost = record.cost=='checked'?false:'checked';
        for (let k = 0; k < this.data.length; k++) {
          this.data[k].cost = cost;
          console.log(k+ " cost>" + this.data[k].cost);
        }
      }else{
        let msg = this.errorMessage[4] ;//+this.errorMessage[err];
        this.$message.error(msg);
      }
       this.dataChange = true;
    },
    saveEnergyUseKindSetup(){
      let err = 0;
      let paramArr = [];
    
       for (let k = 0; k < this.data.length; k++) {
         console.log(this.data[k].registed + "<       >"+ this.data[k].isregisted+ "<       >"+ this.data[k].cost);
        if(this.data[k].tag >= 0){
          let regst = this.data[k].isregisted?'checked':'';
          let cst = this.data[k].cost=='checked'?true:false;
          let item =  {
            cost: cst,
            eneCompanyName: this.data[k].eneCompanyName,
            eneCompanyNo: this.data[k].eneCompanyNo,
            eneMasterId: this.data[k].eneMasterId,
            gasKind: this.data[k].gasKind,
            kindType: this.data[k].kindType,
            registNo: this.data[k].registNo,
            registed: regst,
            tenant: this.data[k].tenant,
            unit: this.data[k].unit,
            measureType: this.data[k].measureType,
            summerMeter: this.data[k].summerMeter,
            winterMeter: this.data[k].winterMeter,
          }
          paramArr.push(item);
        }
        
      }
     
      if(err>0) {
        let msg = this.errorMessage[0] ;//+this.errorMessage[err];
        this.$message.error(msg);
        return;
      }
      this.$confirm({
        title: this.errorMessage[3],
        centered: true,
        onOk: () => {
              this.loading = true;
              let params = {
                action:2,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                selYear: this.selYear,
                energyUsedKindDatas:paramArr
              }
              console.log(params);
              saveEnergyUseKindSetupData(params)
              .then((res) => {
                let msg = '';
                if(res.errorCode=="00"){
                  msg = this.errorMessage[0]+' '+this.errorMessage[1];
                  this.loadEnergyUseKindSetupData(1);
                  this.$message.success(msg);
                }else{
                    msg = this.errorMessage[0]+' '+this.errorMessage[2];
                  this.$message.error(msg);
                }
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });

             // this.loading = false;
          }
      });
    },
    vailString(name,value,min,max){
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
        
        let code = '';
        if(''===value){
          code = "energy.enedata307";
        } else if(value.length<min || value.length>max){
          let msg = this.$t("energy.enedata308");
          msg = msg.replace("{text}",name);
          msg = msg.replace("{star}",min);
          msg = msg.replace("{end}",max);
           return msg;
        } else if(regEn.test(value)) {
           code = "energy.enedata309";
        } else {
           return "";
        }

        let msg = this.$t(code);
        msg = msg.replace("{text}",name);
        return msg;
    },
  },
};
</script>

<style scoped>

#energyUseKindSetup {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
.headertitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.headertitle::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    box-shadow: 0px 3px 8px #e7e6e6;
}

.button{
    display: flex;
    justify-content: center;
    margin-top: 30px    ;
}
input {
  width: 14%;
}
button {
  width: 120px;
  margin: 0 5px;
}
.error{
  border: 1px solid #FF0000;
}
</style>


// handleChange(value, key, column) {
//       const newData = [...this.data];
//       const target = newData.filter(item => key === item.key)[0];
//       if (target) {
//         target[column] = value;
//         this.data = newData;
//       }
//     }